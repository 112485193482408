import i18next from "i18next";
import React, {useState} from "react";
import './back-to-top.scss'

const BackToTopBtn = () => {
    const [scroll, setScroll] = useState<boolean>(false)
    const scrollToTop = () => {
        window.scroll({top: 0, behavior: 'smooth'})
    }

    const shouldShowBtn = () => {
        if (!scroll && window.pageYOffset > 250){
            setScroll(true)
        } else if (scroll && window.pageYOffset <= 250){
            setScroll(false)
        }
    }

    window.addEventListener('scroll', shouldShowBtn)

    if (!scroll){
        return <></>
    } else {
        return <div className="scroll-to-top">
            <div className="scroll-content" onClick={scrollToTop}>
                <img src="https://storage.googleapis.com/clipsymphony_static_assets/Icons/back%20to%20top%20-%20white.svg" alt=""/>
                <span>{i18next.t("Back To Top")}</span>
            </div>
        </div>
    }
}

export default BackToTopBtn
