/**
 * @file .
 */
import React, { Component } from 'react';
import { AppState } from '../../actions/constants';
import { connect } from 'react-redux';

import clipsymphonyImage from '../../assets/logo.png';
import googleMeetImage from '../../assets/google meet.png';
import jiraImage from '../../assets/jira.png';
import mapImage from '../../assets/pic1.png';
import groupImage from '../../assets/pic2.png';
import nerdImage from '../../assets/pic3.png';
import handShakeImage from '../../assets/pic4.png';
import slackImage from '../../assets/slack.png';
import { Link } from 'react-router-dom';

import './job.scss';

interface IProps {
  className?: string;
  id?: string;
	history?: any;
}

export class JobsPage extends Component<IProps, any> {
  public render() {
	  return (
		  <div id="app-jobs">
        <header>
          <Link to="/" ><img src={clipsymphonyImage} alt="clipsymphony logo" /></Link>
        </header>
        <div className="text-image-both">
          <img className="map-image" src={mapImage} alt="developer locations" />
          <div>
            <h1>Who are we?</h1>
            <p>
              A small but well connected team, working together from various corners of the world to expand and improve
              our Enterprise level CMS for the Publishing industry. We believe that we are developing a really cool
              product and our whole team decides together which features shall we add, or how it should look. We are using
              Slack and Google Meet to communicate, sprints based agile as methodology, and of course, Jira, as a project
              management tool.
            </p>
          </div>
          <div className="social-images">
            <article> <img src={googleMeetImage} alt="google meet logo" /></article>
            <article> <img src={slackImage} alt="google meet logo" /> </article>
            <article> <img src={jiraImage} alt="google meet logo" /> </article>
          </div>
        </div>
        <div className="text-center-image-last">
          <section />
          <div>
            <h1>Who are you?</h1>
            <p>
              A junior developer with some knowledge of Golang and Javascript, looking for a full time job and willing
              to work remotely, to learn and grow with us.
            </p>
          </div>
          <img src={nerdImage} alt="logo of a nerd" />
        </div>
        <div className="image-first-text-center">
          <img src={handShakeImage} alt="welcome aboard logo" />
          <div>
            <h1>Sounds good?</h1>
            <p>
              Let’s meet each other! Please tell us a bit a bit about you, by filling in the below Google form! All your
              data is safe with us and we’ll never spam you with emails! We promise! Looking forward to talking to you!
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdM_D_b5I1OFcmtgSZCkW_N-Z80Z2zIal53Rxa1I1gNYNogbw/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer"> Join us! </a>
          </div>
          <section />
        </div>
        <div className="lone-image-center">
          <img src={groupImage} alt="group of people logo" />
        </div>
		  </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: any) => ({});
const mapDispatchToProps = {
};

// `connect` returns a new function that accepts the component to wrap:
export default connect(mapStateToProps, mapDispatchToProps)(JobsPage);
