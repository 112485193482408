import React, { Component } from 'react';
import "./about.scss"
import i18next from 'i18next';

interface IProps {
  className?: string;
  id?: string;
  language: string;
  history?: any;
}

export default class AboutPage extends Component<IProps, any> {

  public render():
    React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
      <div className="About">
        <div>
          <img src="https://storage.googleapis.com/clipsymphony_static_assets/home/about/vector-about.svg" alt="about icon" />
        </div>
        <div className="about">
          <h1> {i18next.t("Our Vision")} </h1>
          <div>
            <p>{i18next.t("At Clipsymphony, we all work everyday to improve publishing industry tools, publishers have a real challenge that the have to deal with an accelerating number of news and they  need to provide well-written articles everyday, and this is our mission to make their life easier")} </p>
            <p>{i18next.t("Throughout the history of this industry tools are invented everyday, but the integrity of these tools is the key get the maximum benefit of them, here Clipsymphony comes to make it true")} </p>
            <p>{i18next.t("Focusing on what our customers need, we are inventing and updating tools everyday to help your work to stand-out and make your life easier, and we will continue doing that believing that we are making a product that will make a real change")} </p>
          </div>
        </div>
      </div>
    );
  }

}

