/**
 * @file .
 */
import React, { Component } from 'react';
import { AppState } from '../../actions/constants';
import { connect } from 'react-redux';

import './under-construction.scss';

import clipsymphonyImage from "../../assets/logo.png";
import constructionImage from "../../assets/under construction.png";
import { Link } from 'react-router-dom';

interface IProps {
  className?: string;
  id?: string;
	history?: any;
}

export class UnderConstructionPage extends Component<IProps, any> {
  public render() {
	  return (
		  <div id="under-construction">
			  <header>
          <img src={clipsymphonyImage} alt="clipsymphony logo" />
        </header>
        <div className="construction-image">
          <img src={constructionImage} alt="construction logo"/>
        </div>
        <footer>
          <h1> OUR AWESOME PRODUCT IS UNDER CONSTRUCTION! </h1>
          <Link to="/careers"> Jobs! </Link>
        </footer>
		  </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: any) => ({});
const mapDispatchToProps = {
};

// `connect` returns a new function that accepts the component to wrap:
export default connect(mapStateToProps, mapDispatchToProps)(UnderConstructionPage);
