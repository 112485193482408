//@ts-ignore
const appServerUrl = window.appServerUrl;
//@ts-ignore
const appRedirectUrl = window.appRedirectUrl;
//@ts-ignore
const languages = window.languages;
//@ts-ignore
const appAuthUrl = window.appAuthUrl;
//@ts-ignore
const env = window.env;
//@ts-ignore
const userMngmtApi = window.userMngmtApi;
//@ts-ignore
const articleMngmtApi = window.articleMngmtApi;
//@ts-ignore
const appDMTApi = window.appDTMApi;
//@ts-ignore
const assetBackendurl = window.assetBackendurl;
//@ts-ignore
const minioServerUrl = window.minioServerUrl;
//@ts-ignore
const articleCdnMngmtApi = window.articleCdnMngmtApi;


export {
  appServerUrl,
  appAuthUrl,
  env,
  userMngmtApi,
  articleMngmtApi,
  appDMTApi,
  assetBackendurl,
  minioServerUrl,
  articleCdnMngmtApi
};
