import { appServerUrl } from "../config";


export class Request {

  static base(url: string, method: string, data: object) {
    return fetch(`${appServerUrl}/${url}`, {
        method: method,
        mode: "no-cors", // no-cors, cors, *same-origin
        // cache: config.env === "dev" ? "no-cache" : "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token") || ""
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json());
  }

  static post(url: string, data: any) {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      // credentials: "include", // include, *same-origin, omit
      // redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        // "Authorization": "Bearer " + localStorage.getItem("token") || ""
      }
    }).then(res => res.json());
  }

  static postForm(url: string, data: FormData) {
    return fetch(url, {
      method: "POST",
      body: data,
      credentials: "include", // include, *same-origin, omit
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token") || ""
      }
    });
  }

  static put(url: string, data: object) {
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include", // include, *same-origin, omit
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token") || ""
      }
    }).then(res => res.json());
  }

  static delete(url: string, data: object) {
    return fetch(url, {
      method: "DELETE",
      body: JSON.stringify(data),
      credentials: "include", // include, *same-origin, omit
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token") || ""
      }
    }).then(res => res.json());
  }

  static get(url: string) {
    return fetch(url, {
      credentials: "include",
      redirect: "follow",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token") || ""
      }
    }).then(response => response.json());
  }

  static get_generic(url: string) {
    return fetch(url, {
      credentials: "include",
      redirect: "follow",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token") || ""
      }
    }).then(response => response.text());
  }
}
