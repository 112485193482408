
import { Reducer } from 'redux';
import { AppState, AppActionAppUpdate } from "../actions/constants";

export const INITIAL_STATE: AppState = {
  app: "/",
};
export const app: Reducer<string, AppActionAppUpdate> = (state=INITIAL_STATE.app, action) => {
  if (action.type === "APP_UPDATE") {
    return action.app;
  } else {
    return state;
  }
};
