import React, { Component } from 'react';
import { UnderConstructionPage } from '../under-construction/under-construction';

interface IProps {
  className?: string;
  id?: string;
  history?: any;
}

export default class TermsAndConditionsPage extends Component<IProps, any> {

  public render():
    React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
      <div className="terms-conditions">
        <UnderConstructionPage />
      </div>
    );
  }

}

