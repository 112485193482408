import { createStore, compose, applyMiddleware, combineReducers, Reducer, StoreEnhancer } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer.js';
import { app } from './reducers/app';
import { AppState, AppAction } from './actions/constants';

// Overall state extends static states and partials lazy states.
export interface RootState extends AppState{}
export type RootAction = AppAction;


// Sets up a Chrome extension for time travel debugging.
declare global {
  interface Window {
    process?: Object;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const devCompose: <Ext0, Ext1, StateExt0, StateExt1>(
  f1: StoreEnhancer<Ext0, StateExt0>, f2: StoreEnhancer<Ext1, StateExt1>
) => StoreEnhancer<Ext0 & Ext1, StateExt0 & StateExt1> =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create the store
export const store = createStore(
  state => state as Reducer<RootState, RootAction>,
  devCompose( lazyReducerEnhancer(combineReducers), applyMiddleware(thunk as ThunkMiddleware<RootState, RootAction>)),
);

// @ts-ignore
store.addReducers({ app });
