// this is the localization package for translating languages on the frontend
// @ts-ignore
import i18next from "i18next";

//@ts-ignore support pulling translations from the backend.
import Backend from 'i18next-xhr-backend';

//@ts-ignore cache http results as a result of translations
import Cache from 'i18next-localstorage-cache';

//@ts-ignore detect the user preferred language in the browser.
import LanguageDetector from 'i18next-browser-languagedetector';

export default () => {
	return i18next
		.use(Backend)
		.use(Cache)
		.use(LanguageDetector)
		.init({
			lng: window.localStorage.i18nextLng || 'de',
			fallbackLng: window.localStorage.i18nextLng || 'de',
			debug: false,
			preload: ['en', 'de'],
			backend: {
				loadPath: "/api/locales/{{lng}}",
				crossDomain: true,
				withCredentials: false,
			}
		});
}

